import classNames from "classnames";
import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

const Button = ({ className, children, ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      className={classNames(
        "px-7 py-2 bg-[#02C75A] text-white font-bold text-base rounded-md",
        className
      )}
    >
      {children}
    </button>
  );
};

export default Button;
