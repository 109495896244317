import React, { useCallback, useMemo } from "react";
import AddPeopleIcon from "../assets/AddPeopleIcon";
import MainEtcIcon from "../assets/MainEtcIcon";
import dayjs from "dayjs";
import { Editable, Slate, withReact } from "slate-react";
import { createEditor } from "slate";
import Leaf from "./editorUtils/Leaf";
import Element from "./editorUtils/Element";

interface PostContentProps {
  title: string;
  author: string;
  profileImageUrl: string;
  createdAt: string;
  content: string;
}

const PostContent = ({
  title,
  author,
  profileImageUrl,
  createdAt,
  content,
}: PostContentProps) => {
  const editor = useMemo(() => withReact(createEditor()), []);

  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />;
  }, []);

  const renderElement = useCallback((props: any) => {
    return <Element {...props} />;
  }, []);

  return (
    <section className="pt-[55px] w-screen min-h-screen flex flex-col items-center">
      <article className=" max-w-full w-[44rem] h-screen ">
        <hgroup className="pt-10 pb-6 px-6 flex flex-col gap-4 border-solid border-b-[1px] border-[#00000019]">
          {/* <span className="text-[13px]">당뇨와 나</span> */}
          <h1 className="text-[32px]">{title}</h1>
          <figure className="flex w-full p-1 items-center gap-2">
            <img
              className="w-[36px] h-[36px] rounded-full"
              alt="profile"
              src={profileImageUrl}
            />
            <div className="flex flex-col">
              <span className="text-[14px] text-[#555]">{author}</span>
              <time className="text-[12px] text-[#8C8C8C] helvetica">
                {dayjs(createdAt).format("YYYY. M. D. H:M")}
              </time>
            </div>
            <div className="ml-auto flex items-center gap-2">
              <button className="w-[86px] h-[32px] px-[13px] flex items-center justify-center border-[1px] border-solid border-[#03c75a] text-[#03c75a] text-[11px] gap-1">
                <AddPeopleIcon />
                이웃 추가
              </button>
              <MainEtcIcon />
            </div>
          </figure>
        </hgroup>
        <div className="py-10">
          <Slate editor={editor} initialValue={JSON.parse(content)}>
            <Editable
              renderLeaf={renderLeaf}
              renderElement={renderElement}
              readOnly
            />
          </Slate>
        </div>
      </article>
    </section>
  );
};

export default PostContent;
