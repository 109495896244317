import React, { SVGProps } from "react";

const ArrowIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14"
      height="9"
      {...props}
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6918 0.308113C13.4945 0.110797 13.2268 -4.86374e-05 12.9477 -4.86374e-05C12.6686 -4.86374e-05 12.401 0.110797 12.2036 0.308113L6.99365 5.51805L1.78371 0.308113C1.5852 0.116389 1.31933 0.010302 1.04337 0.0127001C0.767401 0.0150981 0.503418 0.125789 0.308273 0.320934C0.113129 0.516078 0.00243812 0.780062 4.00543e-05 1.05603C-0.00235801 1.33199 0.103729 1.59786 0.295453 1.79637L6.24952 7.75043C6.44689 7.94775 6.71456 8.05859 6.99365 8.05859C7.27273 8.05859 7.5404 7.94775 7.73777 7.75043L13.6918 1.79637C13.8892 1.59899 14 1.33133 14 1.05224C14 0.773151 13.8892 0.505488 13.6918 0.308113Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default ArrowIcon;
