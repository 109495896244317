const Element = (props: any) => {
  const { attributes, children, element } = props;

  if (element.type === "inyonggu") {
    return (
      <div
        {...attributes}
        className="w-full flex items-center flex-col select-none bg-white cursor-pointer"
      >
        <img
          src="/quoteup.png"
          alt="Custom"
          className="select-none"
          style={{ height: "44px", display: "block", margin: "10px 0" }}
        />
        <p
          className="nanummyeongjo"
          style={{ fontWeight: "bold", fontSize: "18px" }}
        >
          {children}
        </p>
        <img
          src="/quotedown.png"
          alt="Custom"
          style={{ height: "44px", display: "block", margin: "10px 0" }}
        />
      </div>
    );
  }
  if (element.type === "image") {
    return (
      <img
        onClick={() => console.log(3)}
        src={element.data}
        className=""
        alt=""
      />
    );
  }
  if (element.type === "link") {
    const { data } = element.children[0];
    return (
      <a
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        {...attributes}
        href={data.url}
        className="w-1/2 h-32 flex items-center border-solid border-[1px] border-[#ccc] cursor-pointer"
      >
        {data.image && (
          <img src={data.image} alt="logo" className="h-full max-w-32" />
        )}
        <div className="flex flex-col p-6 h-full justify-between">
          <h1 className=" font-semibold">{data.title}</h1>
          <span className=" text-ellipsis overflow-hidden line-clamp-1 text-[14px] text-[#aaa]">
            {data.description}
          </span>
          <span className="text-[12px] text-primary">{data.url}</span>
        </div>
      </a>
    );
  }
  return (
    <div
      style={{
        textAlign: element.children[0].textAlign || "left",
      }}
      {...attributes}
    >
      {children}
    </div>
  );
};

export default Element;
