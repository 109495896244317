import React from "react";
import { Link } from "react-router-dom";

interface HeaderProps {
  isMockLoggedIn?: boolean;
  fill?: boolean;
}

const Header = ({ isMockLoggedIn, fill }: HeaderProps) => {
  return (
    <>
      <div className="w-full h-[66px] bg-[#02C75A] fixed top-0 flex items-center px-10 shadow-lg justify-between z-[1000]">
        <hgroup className="flex items-center gap-20">
          <Link
            to={isMockLoggedIn ? "/admin/home" : "#"}
            className="text-white font-bold font-['Pretendard'] text-[22px]"
          >
            MINCOMPANY
          </Link>
          {isMockLoggedIn && (
            <Link to="/admin/home" className="text-[18px] text-white font-bold">
              홈
            </Link>
          )}
        </hgroup>
        {isMockLoggedIn && (
          <div className="flex items-center gap-16">
            <Link
              to="/admin/manage-profile"
              className="text-[18px] text-white font-bold"
            >
              댓글용 프로필 관리
            </Link>
            <Link
              to="/admin/write"
              className="text-[18px] text-white font-bold"
            >
              글 작성
            </Link>
          </div>
        )}
      </div>
      {fill && <div className="w-full h-[66px]">#</div>}
    </>
  );
};

export default Header;
