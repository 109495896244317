import React from "react";
import { useModal } from "../../hooks/useModal";
import { useQuery } from "@tanstack/react-query";
import { mincompany } from "../../apis/instance";

interface SelectProfileModalProps {
  handleSelect: (id: number) => void;
}

const SelectProfileModal = ({ handleSelect }: SelectProfileModalProps) => {
  const { data, isSuccess } = useQuery({
    queryKey: ["profiles"],
    queryFn: async () => (await mincompany.get("/users")).data,
  });

  const { closeModal } = useModal();

  const handleSelectProfile = (profile: any) => {
    handleSelect(profile);
    closeModal();
  };

  return (
    <div className="bg-white h-[90vh] w-[80vw] p-10 flex flex-col gap-10 z-[2000000]">
      <h1 className="text-2xl font-bold">사용할 프로필 선택</h1>
      <ul className="grid grid-cols-4 gap-8 justify-between overflow-y-scroll">
        {isSuccess &&
          data.map((profile: any) => (
            <li
              key={profile.id}
              onClick={() => handleSelectProfile(profile)}
              className="w-full cursor-pointer p-3 border-solid border-2 border-[#f2f3f7] flex items-center justify-between"
            >
              <figure className="flex items-center gap-4">
                <img
                  src={profile.profileImageUrl}
                  alt="profile"
                  className="w-10 h-10 rounded-full"
                />
                <span className="text-lg font-semibold">
                  {profile.nickname}
                </span>
              </figure>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SelectProfileModal;
