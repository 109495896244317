import classNames from "classnames";
import React from "react";

interface CheckProps {
  val: boolean;
  set: (v: any) => void;
}

const Check = ({ set, val }: CheckProps) => {
  return (
    <div
      onClick={set}
      className={classNames(
        "w-[16px] h-[16px] text-[8px] rounded-sm flex items-center justify-center border-solid border-[1px] border-primary cursor-pointer",
        !val ? "bg-white text-black" : "bg-primary text-white"
      )}
    >
      ✓
    </div>
  );
};

export default Check;
