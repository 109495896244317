import React, { useState } from "react";
import PostHeader from "../../components/PostHeader";
import PostContent from "../../components/PostContent";
import PostFooter from "../../components/PostFooter";
import Header from "../../components/Header";
import Button from "../../components/atoms/Button";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CommentList from "../../components/comments/CommentList";
import { useQuery } from "@tanstack/react-query";
import { mincompany } from "../../apis/instance";

const AdminPost = () => {
  const [isPostOpen, setIsPostOpen] = useState(true);
  const { id } = useParams();
  const { isSuccess, data } = useQuery({
    queryKey: ["post", id],
    queryFn: async () => (await mincompany.get(`/posts/${id}`)).data,
  });
  const navigate = useNavigate();

  if (isSuccess)
    return (
      <main className="w-screen h-screen flex flex-col gap-10">
        <Header isMockLoggedIn fill />
        <hgroup className="z-[100] fixed pt-[100px] bg-white py-10 px-10 border-solid border-2 border-[#f2f3f7] w-full flex items-center h-20 justify-between">
          <h1 className="font-bold text-3xl">글 관리</h1>
          <section className="flex items-center gap-2">
            <Button
              onClick={() => setIsPostOpen((prev) => !prev)}
              className="!text-[14px] !px-5 !py-[6px] border-solid border-primary !border-2 !bg-white !text-primary "
            >
              {isPostOpen ? "글 닫기" : "글 열기"}
            </Button>
            <Button
              onClick={() => navigate(`/post/${id}`)}
              className="!text-[14px] !px-5 !py-[6px]"
            >
              사용자 화면으로 보기
            </Button>
            <Button
              onClick={() => navigate(`/admin/${id}/edit`)}
              className="!text-[14px] !px-5 !py-[6px]"
            >
              게시글 수정
            </Button>
          </section>
        </hgroup>
        <div className="w-full h-[400px] my-3">#</div>
        <article className="flex flex-col">
          {isPostOpen && (
            <>
              <PostHeader title={data.title} preview />
              <PostContent
                title={data.title}
                author={data.author}
                profileImageUrl={data.authorProfileImageUrl}
                createdAt={data.createdAt}
                content={data.content}
              />
              <PostFooter tags={data.tags} likeCount={data.likeCount} />
            </>
          )}
          <article className="w-full flex flex-col items-center">
            <CommentList user={data} postId={Number(id)} />
          </article>
        </article>
      </main>
    );
  return <></>;
};

export default AdminPost;
