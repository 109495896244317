import React, { useEffect, useState } from "react";
import ArrowIcon from "../../assets/ArrowIcon";
import { useModal } from "../../hooks/useModal";
import SelectProfileModal from "../modals/SelectProfileModal";

const SelectProfile = ({ setUserId }: { setUserId: (id: number) => void }) => {
  const { openModal } = useModal();
  const [selectedProfile, setSelectedProfile] = useState<any>();

  useEffect(() => {
    setUserId(selectedProfile?.id);
    // eslint-disable-next-line
  }, [selectedProfile]);

  return (
    <div className="relative">
      <section
        onClick={() =>
          openModal(<SelectProfileModal handleSelect={setSelectedProfile} />)
        }
        className="flex items-center gap-2 px-4 py-2 cursor-pointer border-solid border-[1.5px] border-[#ccc]"
      >
        <img
          src={selectedProfile?.profileImageUrl || "/img_profile_default.png"}
          alt="profile"
          className="w-6 h-6 rounded-full"
        />
        <span className="text-[13px] font-[700] font-['helvetica'] text-[#2a2a2a]">
          {selectedProfile?.nickname || "사용할 프로필 선택"}
        </span>
        <ArrowIcon />
      </section>
    </div>
  );
};

export default SelectProfile;
