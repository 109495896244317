import React from "react";
import { useParams } from "react-router-dom";
import Editor from "../../components/comments/BaseEditor";
import { useQuery } from "@tanstack/react-query";
import { mincompany } from "../../apis/instance";

const AdminEdit = () => {
  const { id } = useParams();
  console.log(id);

  const { isSuccess, data } = useQuery({
    queryKey: ["post", id],
    queryFn: async () => (await mincompany.get(`/posts/${id}`)).data,
  });

  if (isSuccess) return <Editor edit base={data} />;
  return <></>;
};

export default AdminEdit;
