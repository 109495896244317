import { useSlate } from "slate-react";
import { Transforms } from "slate";
import { useState } from "react";
import ArrowIcon from "../../assets/ArrowIcon";

const fontArray = ["본문", "소제목", "인용구"];

export const TextTypeButton = () => {
  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  const [font, setFont] = useState("본문");

  const addBlock = (type: string) => {
    if (type === "본문") {
      const newBlock = {
        type: "paragraph",
        children: [{ text: "", font: "15px" }],
      };

      Transforms.insertNodes(editor, newBlock);
    } else if (type === "소제목") {
      const newBlock = {
        type: "paragraph",
        children: [{ text: "소제목", font: "30px" }],
      };

      Transforms.insertNodes(editor, newBlock);

      const newBlock2 = {
        type: "paragraph",
        children: [{ text: " ", font: "15px" }],
      };

      Transforms.insertNodes(editor, newBlock2);
    } else if (type === "인용구") {
      const newBlock = {
        type: "inyonggu",
        children: [{ text: "내용을 입력하세요.", fontFamily: "nanummyeongjo" }],
      };
      Transforms.insertNodes(editor, newBlock);
      const newBlock2 = {
        type: "paragraph",
        children: [{ text: " ", font: "15px" }],
      };

      Transforms.insertNodes(editor, newBlock2);
    }
  };

  return (
    <>
      {isOpen && (
        <>
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="fixed w-full h-full z-[100000] top-0 left-0"
          />
          <ul className="absolute top-0 left-0 bg-white z-[200000] border-[1px] border-solid shadow-lg">
            {fontArray.map((font) => {
              return (
                <div
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  onClick={() => {
                    setIsOpen(false);
                    // setFont(font);
                    addBlock(font);
                  }}
                  className="py-2 px-4 h-full hover:brightness-95 bg-white cursor-pointer z-[300000]"
                  key={font}
                >
                  {font}
                </div>
              );
            })}
          </ul>
        </>
      )}
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        className="relative text-[12px] text-[#333] flex items-center gap-3"
      >
        {font}
        <ArrowIcon width={9} />
      </button>
    </>
  );
};
