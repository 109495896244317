import React from "react";
import LikeIcon from "../assets/LikeIcon";

interface PostFooterProps {
  tags: string;
  likeCount: number;
}

const PostFooter = ({ tags, likeCount }: PostFooterProps) => {
  const tagList = tags[0].split(" ");
  return (
    <footer className="py-[20px] w-screen flex flex-col items-center">
      <article className="w-[44rem] flex flex-col gap-6">
        <div className="w-full flex gap-2">
          {tagList.map((tag: string) => (
            <div className="bg-[#EEE] flex items-center text-[12px]  justify-center px-3 py-1">
              {tag.replaceAll('"', "")}
            </div>
          ))}
        </div>
        <div className="border-solid w-fit text-[12px] border-[1px] border-[#ccc] flex items-center justify-center px-3 py-1 gap-2">
          <LikeIcon /> 공감 {likeCount}
        </div>
      </article>
    </footer>
  );
};

export default PostFooter;
