import React from "react";
import Header from "../../components/Header";
import Button from "../../components/atoms/Button";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { mincompany } from "../../apis/instance";

const AdminHome = () => {
  const { data, isSuccess } = useQuery({
    queryKey: ["posts"],
    queryFn: async () => (await mincompany.get("/posts")).data,
  });
  const queyrClient = useQueryClient();

  const { mutateAsync: deletePost } = useMutation({
    mutationFn: async (id: number) => await mincompany.delete(`/posts/${id}`),
  });

  const navigate = useNavigate();

  const handleDelete = async (id: number) => {
    const { isConfirmed } = await Swal.fire({
      title: "게시글 삭제",
      text: "정말로 이 글을 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "red",
      cancelButtonText: "확인",
      confirmButtonColor: "gray",
      confirmButtonText: "취소",
    });
    // 게시물 삭제 확인 버튼 클릭
    if (!isConfirmed) {
      await deletePost(id);
      queyrClient.invalidateQueries({ queryKey: ["posts"] });
      toast.success("게시글이 삭제되었습니다.");
      navigate("/admin/home");
    }
  };

  return (
    <main className="min-h-screen w-screen">
      <Header isMockLoggedIn fill />
      <div className="w-full h-full flex flex-col p-20">
        <hgroup className="py-10 flex flex-col gap-4">
          <h1 className="text-3xl font-semibold">글 목록</h1>
          <div className="w-full h-[1px] bg-[#ccc]" />
        </hgroup>
        <ul>
          {isSuccess &&
            data.map((res: any) => (
              <div
                className="w-full p-10 flex gap-8 border-solid border-b-2 border-[#f2f3f7] cursor-pointer hover:brightness-[0.98] bg-white"
                key={res.id}
              >
                <Link
                  to={`/admin/${res.id}`}
                  className="flex flex-col gap-6 w-full"
                >
                  <hgroup className="flex items-center justify-between">
                    <figure className="flex gap-4 items-center">
                      <img
                        src={res.authorProfileImageUrl}
                        className="rounded-full w-12 h-12"
                        alt="profile"
                      />
                      <div className="flex flex-col">
                        <span className="text-base font-medium">
                          {res.author}
                        </span>
                        <time className="text-sm text-[#999]">
                          {res.author}
                        </time>
                      </div>
                    </figure>
                  </hgroup>
                  <hgroup className="flex flex-col gap-3">
                    <h1 className="font-bold text-2xl">{res.title}</h1>
                  </hgroup>
                </Link>
                {/*  */}
                <article className="flex flex-col gap-2 w-96 ml-auto">
                  <figure className="flex gap-2 ml-auto">
                    <Button
                      onClick={() => navigate(`/admin/${res.id}/edit`)}
                      className="!text-[14px] !px-5 !py-[2px]"
                    >
                      수정
                    </Button>
                    <Button
                      onClick={() => handleDelete(res.id)}
                      className="!text-[14px] !px-5 !py-[2px] !bg-red-500 "
                    >
                      삭제
                    </Button>
                  </figure>
                </article>
              </div>
            ))}
        </ul>
      </div>
    </main>
  );
};

export default AdminHome;
