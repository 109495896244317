import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import ToolBoxArrowIcon from "../../assets/ToolBoxArrowIcon";
import classNames from "classnames";
import ArrowIcon from "../../assets/ArrowIcon";
import { Editable, Slate, withReact } from "slate-react";
import { createEditor, Editor as SlateEditor, Text, Transforms } from "slate";
import { BoldButton } from "../editorUtils/Bold";
import Leaf from "../editorUtils/Leaf";
import { ItalicButton } from "../editorUtils/Italic";
import { UnderlineButton } from "../editorUtils/Underline";
import { CancelButton } from "../editorUtils/Cancel";
import { ColorButton } from "../editorUtils/Color";
import { BackgroundButton } from "../editorUtils/Background";
import { AlignButton } from "../editorUtils/Align";
import Element from "../editorUtils/Element";
import { LineHeightButton } from "../editorUtils/LineHeight";
import { toast } from "react-toastify";
import { FontSizeButton } from "../editorUtils/FontSize";
import InlineTools from "../editorUtils/InlineTools";
import { TextTypeButton } from "../editorUtils/TextType";
import { useModal } from "../../hooks/useModal";
import Button from "../atoms/Button";
import EmbedModal from "../modals/EmbedModal";
import { useAtom } from "jotai";
import { mockAtom } from "../../context";
import { mincompany } from "../../apis/instance";
import { authorization } from "../../apis/authorization";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import PasteUpload from "../PasteUpload";

interface Props {
  title: string;
  content: any;
  base: any;
}

const Publish = ({ title, content, base }: Props) => {
  const { data } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => (await mincompany.get("/categories")).data,
  });
  const [profileUrl, setProfileUrl] = useState("/img_profile_default.png");
  const [nicknma, setNicnk] = useState("");

  const handleProfileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const { data } = await mincompany.post(
        "/files",
        formData,
        authorization()
      );
      setProfileUrl(data);
    }
  };

  const { closeModal } = useModal();

  const [descirption, setDescription] = useState("");
  const [peoplCount, setPeopleCout] = useState("");
  const [gongga, setGongg] = useState("");
  const [nalccha, setNalccha] = useState<any>({ a: "", b: "" });
  const [tags, setTags] = useState("");
  const [select, setSelect] = useState<any>();

  useEffect(() => {
    if (base) {
      console.log(base);
      setNicnk(base.author);
      setProfileUrl(base.authorProfileImageUrl);
      setNalccha((prev: any) => ({ ...prev, a: base.createdAt.split("T")[0] }));
      const a = base.createdAt.split("T")[1].split(":");
      setNalccha((prev: any) => ({ ...prev, b: `${a[0]}:${a[1]}` }));
      setDescription(base.authorBio);
      setTags(base.tags[0].replaceAll('"', ""));
      setPeopleCout(base.neighborCount);
      setGongg(base.likeCount);
    }
  }, [base]);

  const hnadlenlachagDatefda = (e: ChangeEvent<HTMLInputElement>) => {
    setNalccha((prev: any) => ({ ...prev, a: e.target.value }));
  };

  const handlanlkoiHLFnjTime = (e: ChangeEvent<HTMLInputElement>) => {
    setNalccha((prev: any) => ({ ...prev, b: e.target.value }));
  };

  const navigate = useNavigate();
  const hanfleIOHOI = async () => {
    const utcDate = new Date(`${nalccha.a}T${nalccha.b}Z`);

    await mincompany
      .post(
        "/posts",
        {
          title,
          content: JSON.stringify(content),
          author: nicknma,
          authorProfileImageUrl: profileUrl,
          likeCount: gongga,
          neighborCount: peoplCount,
          authorBio: descirption,
          tags: [JSON.stringify(tags)],
          createdAt: utcDate,
        },
        authorization()
      )
      .then(() => {
        navigate("/admin/home");
        closeModal();
      });
  };

  const hanfleEEEEEEEdit = async () => {
    const utcDate = new Date(`${nalccha.a}T${nalccha.b}Z`);

    await mincompany
      .patch(
        `/posts/${base.id}`,
        {
          title,
          content: JSON.stringify(content),
          likeCount: gongga,
          tags: [JSON.stringify(tags)],
          createdAt: utcDate,
        },
        authorization()
      )
      .then(() => {
        navigate("/admin/home");
        closeModal();
      });
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-[40vw] flex flex-col bg-white p-4 gap-6">
      {isOpen && (
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-4 border-solid border-2 border-primary flex flex-col gap-2">
          {data.map((asd: any) => (
            <div
              onClick={() => {
                setSelect(asd);
                setIsOpen(false);
              }}
              key={asd.id}
              className="border-solid bg-white border-2 items-center h-[28px] p-2 text-[10px] outline-none w-full border-[#ccc]"
            >
              {asd.name}
            </div>
          ))}
        </div>
      )}
      <figure className="flex items-center gap-2">
        <span className="text-[14px] w-20 font-medium mb-auto">태그 편집</span>
        <textarea
          onChange={({ target: { value } }) => setTags(value)}
          value={tags}
          placeholder="#태그 입력 (최대 30개)"
          className="border-solid border-[1px] h-[80px] p-2 text-[10px] outline-none w-full border-[#ccc] resize-none"
        />
      </figure>
      <div className="w-full h-[1px] bg-[#eee]" />
      <figure className="flex items-center gap-2">
        <span className="text-[12px] w-20 font-medium">작성자 닉네임</span>
        <input
          disabled={!!base}
          onChange={({ target: { value } }) => setNicnk(value)}
          value={nicknma}
          className="border-solid disabled:bg-gray-200 border-[1px] h-[28px] p-2 text-[10px] outline-none w-full border-[#ccc]"
        />
      </figure>
      <figure className="flex items-center gap-2">
        <span className="text-[14px] w-20 font-medium">작성자 소개</span>
        <input
          disabled={!!base}
          onChange={({ target: { value } }) => setDescription(value)}
          value={descirption}
          className="border-solid disabled:bg-gray-200 border-[1px] h-[28px] p-2 text-[10px] outline-none w-full border-[#ccc]"
        />
      </figure>
      <figure className="flex items-center gap-2">
        <span className="text-[14px] w-20 font-medium">이웃 수</span>
        <input
          disabled={!!base}
          onChange={({ target: { value } }) => setPeopleCout(value)}
          value={peoplCount}
          className="border-solid border-[1px] disabled:bg-gray-200 h-[28px] p-2 text-[10px] outline-none w-full border-[#ccc]"
        />
      </figure>
      <figure className="flex items-center gap-2">
        <span className="text-[14px] w-20 font-medium">공감 수</span>
        <input
          onChange={({ target: { value } }) => setGongg(value)}
          value={gongga}
          className="border-solid border-[1px] h-[28px] p-2 text-[10px] outline-none w-full border-[#ccc]"
        />
      </figure>
      <figure className="flex items-center gap-2">
        <span className="text-[14px] w-20 font-medium">업로드 날짜</span>
        <div className="flex items-center gap-2">
          <input
            onChange={hnadlenlachagDatefda}
            value={nalccha.a}
            type="date"
            className="border-solid border-[1px] h-[28px] p-2 text-[10px] outline-none w-full border-[#ccc]"
          />
          <input
            onChange={handlanlkoiHLFnjTime}
            value={nalccha.b}
            type="time"
            className="border-solid border-[1px] h-[28px] p-2 text-[10px] outline-none w-full border-[#ccc]"
          />
        </div>
      </figure>
      <figure className="flex items-center gap-2">
        <span className="text-[14px] w-20 font-medium">프로필 사진</span>
        <div className="rounded-full w-10 h-10  relative">
          <img
            src={profileUrl}
            alt="mock"
            className="w-full h-full rounded-full absolute"
          />
          {!base && (
            <label
              htmlFor="file"
              className="w-full h-full cursor-pointer rounded-full bg-black bg-opacity-20 absolute flex items-center justify-center"
            >
              <span className="text-3xl text-white font-bold">+</span>
            </label>
          )}
          <input
            onChange={handleProfileUpload}
            id="file"
            type="file"
            className="hidden"
          />
        </div>
      </figure>
      <Button onClick={base ? hanfleEEEEEEEdit : hanfleIOHOI}>발행</Button>
    </div>
  );
};

interface EditorProps {
  edit?: boolean;
  base?: any;
}

const Editor = ({ edit, base }: EditorProps) => {
  const { openModal } = useModal();
  const [editor] = useState(() => withReact(createEditor()));
  const [value, setValue] = useState(
    edit
      ? JSON.parse(base.content)
      : [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ]
  );

  const [showToolbar, setShowToolbar] = useState(false);
  const [selection, setSelection] = useState(null);
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });

  // 텍스트 포맷팅 함수
  const formatText = (format: string) => {
    if (selection) {
      Transforms.select(editor, selection);
      Transforms.setNodes(
        editor,
        { [format]: true },
        { match: (n) => Text.isText(n), split: true }
      );
    }
  };

  const [title, setTitle] = useState("");
  const handlePublish = () => {
    openModal(<Publish title={title} content={value} base={base} />);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();

      const { selection } = editor;
      if (selection) {
        const [match]: any = SlateEditor.nodes(editor, {
          match: (n) => (n as any).type === "paragraph",
        });

        // 새 줄을 삽입하고 스타일 초기화
        Transforms.insertNodes(editor, {
          type: "paragraph",
          children: [{ text: "" }],
        } as any);
      }
    }
  };

  useEffect(() => {
    if (edit) {
      setTitle(base.title);
    }
  }, [edit, base]);

  // selection 변화 감지하여 툴바 위치 조정

  // useEffect(() => {
  //   window.addEventListener("mouseup", handleSelectionChange);
  //   return () => window.removeEventListener("mouseup", handleSelectionChange);
  // }, [editor.selection]);

  useEffect(() => {
    const handleSelectionChange = () => {
      const domSelection = window.getSelection();
      if (domSelection && domSelection.rangeCount > 0) {
        const domRange = domSelection.getRangeAt(0);
        const rect = domRange.getBoundingClientRect();

        setToolbarPosition({
          top: rect.top - 40,
          left: rect.left + rect.width / 2 - 50,
        });

        setShowToolbar(!domSelection.isCollapsed);
        setSelection(editor.selection as any);
      }
    };

    window.addEventListener("mouseup", handleSelectionChange);
    window.addEventListener("keyup", handleSelectionChange);
    return () => {
      window.removeEventListener("mouseup", handleSelectionChange);
      window.removeEventListener("keyup", handleSelectionChange);
    };
  }, [editor.selection]);

  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />;
  }, []);

  const renderElement = useCallback((props: any) => {
    return <Element {...props} />;
  }, []);

  const [mock, setMock] = useAtom(mockAtom);

  const handleChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const { data } = await mincompany.post(
        "/files",
        formData,
        authorization()
      );

      const image = { type: "image", data, children: [{ text: "" }] };

      Transforms.insertNodes(editor, image);

      const newBlock2 = {
        type: "paragraph",
        children: [{ text: " ", font: "15px" }],
      };

      Transforms.insertNodes(editor, newBlock2);
    }
  };

  const addBlockInyong = () => {
    const newBlock = {
      type: "inyonggu",
      children: [{ text: "내용을 입력하세요.", fontFamily: "nanummyeongjo" }],
    };
    Transforms.insertNodes(editor, newBlock);
    const newBlock2 = {
      type: "paragraph",
      children: [{ text: " ", font: "15px" }],
    };

    Transforms.insertNodes(editor, newBlock2);
  };

  const onUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    const { data } = await mincompany.post("/files", formData);

    const newBlock = {
      type: "image",
      data,
      children: [{ text: "" }],
    };
    Transforms.insertNodes(editor, newBlock);
    const newBlock2 = {
      type: "paragraph",
      children: [{ text: " ", font: "15px" }],
    };

    Transforms.insertNodes(editor, newBlock2);
  };

  return (
    <Slate
      initialValue={value}
      editor={editor}
      onChange={(value) => setValue(value as any)}
    >
      <main className="w-screen min-h-screen flex flex-col">
        <div className="flex flex-col fixed w-screen z-[100]">
          <header className="flex items-center px-5 w-full h-[44px] border-solid border-[1px] border-[#E5E5E5] justify-between bg-white">
            <hgroup className="flex items-center gap-[6px]">
              <img src="/naver.svg" alt="naver" />
              <img src="/logo.svg" alt="logo" className="w-[36px] h-[21px]" />
            </hgroup>
            <figure className="flex items-center gap-3">
              <figure className="flex items-center gap-2">
                <button className="w-[70px] h-[30px] border-[#E5E5E5] border-[1px] border-solid flex items-center justify-evenly">
                  <span className="text-[12px] text-[#333]">저장</span>
                  <div className="separator w-[1px] h-[40%] bg-[#ccc]" />
                  <span className="text-[12px] pl-[3px] text-primary">1</span>
                </button>
                <button
                  onClick={handlePublish}
                  className="w-[70px] h-[30px] font-bold flex items-center justify-center gap-2 bg-primary"
                >
                  <span className="text-[12px] text-white">발행</span>
                  <img src="/publish.svg" alt="발행" />
                </button>
              </figure>
              <button type="button">
                <img src="/more.svg" alt="더보기" />
              </button>
            </figure>
          </header>
          <header className="w-full h-[61px] px-3 border-solid border-[1px] border-[#E5E5E5] flex items-center justify-between bg-white">
            <input
              onChange={handleChangeFile}
              type="file"
              id="file"
              className="hidden"
            />
            <section className="flex items-center gap-3 h-full">
              {TOOL_BOX_FRONT_BEFORE.map((toolbox) => (
                <label
                  htmlFor={!toolbox.mock ? "file" : ""}
                  className="flex flex-col items-center justify-center w-[40px] h-full gap-1 hover:brightness-95 bg-white"
                >
                  <img
                    src={toolbox.icon}
                    alt={toolbox.name}
                    className="w-auto h-[22px]"
                  />
                  <span className="text-[11px] text-[#555]">
                    {toolbox.name}
                  </span>
                </label>
              ))}
              <button
                onClick={addBlockInyong}
                className="flex flex-col items-center justify-center w-[40px] h-full gap-1 hover:brightness-95 bg-white"
              >
                <figure className="flex w-full items-center justify-evenly h-[22px]">
                  <img
                    src="/toolbox/quote.png"
                    alt="인용구"
                    className="w-auto h-[14px]"
                  />
                  <i className="h-full flex items-center justify-center">
                    <ToolBoxArrowIcon className="rotate-180 w-[5px]" />
                  </i>
                </figure>
                <span className="text-[11px] text-[#555]">인용구</span>
              </button>
              <button className="flex flex-col items-center justify-center w-[40px] h-full gap-1 hover:brightness-95 bg-white">
                <figure className="flex w-full items-center justify-evenly h-[22px]">
                  <img
                    src="/toolbox/separator.png"
                    alt="구분선"
                    className="w-[20px] h-auto"
                  />
                  <i className="h-full flex items-center justify-center">
                    <ToolBoxArrowIcon className="rotate-180 w-[5px]" />
                  </i>
                </figure>
                <span className="text-[11px] text-[#555]">구분선</span>
              </button>
              {mock && <EmbedModal />}
              {TOOL_BOX_FRONT_AFTER.map((toolbox) => (
                <button
                  onClick={
                    !toolbox.mock
                      ? () => {
                          setMock(true);
                        }
                      : () => {}
                  }
                  className="flex flex-col items-center justify-center w-[40px] h-full gap-1 hover:brightness-95 bg-white"
                >
                  <img
                    src={toolbox.icon}
                    alt={toolbox.name}
                    className="w-auto h-[22px]"
                  />
                  <span className="text-[11px] text-[#555]">
                    {toolbox.name}
                  </span>
                </button>
              ))}
            </section>
            <section className="flex items-center gap-3 h-full">
              {TOOL_BOX_BACK.map((toolbox) => (
                <button className="flex flex-col items-center justify-center w-[40px] h-full gap-1 hover:brightness-95 bg-white">
                  <img
                    src={toolbox.icon}
                    alt={toolbox.name}
                    className="w-auto h-[22px]"
                  />
                  <span
                    className={classNames(
                      "text-[11px] text-[#555]",
                      toolbox.name === "라이브러리" && "text-[9px]"
                    )}
                  >
                    {toolbox.name}
                  </span>
                </button>
              ))}
            </section>
          </header>
          <header className="w-full h-[33px] px-4 flex items-center border-solid border-b-[1px] border-[#BCBCBC] gap-4 shadow-sm bg-white">
            <section className="flex gap-8 items-center">
              <TextTypeButton />
              <button
                onClick={() => toast.error("지원하지 않는 기능입니다.")}
                className="text-[12px] text-[#333] flex items-center gap-3"
              >
                나눔고딕
                <ArrowIcon width={9} />
              </button>
              <div className="relative">
                <FontSizeButton />
              </div>
            </section>
            <div className="w-[1px] h-[50%] bg-[#E5E5E5]" />
            <section className="flex items-center gap-2 relative">
              <BoldButton />
              <ItalicButton />
              <UnderlineButton />
              <CancelButton />
              <ColorButton />
              <BackgroundButton />
            </section>
            <div className="w-[1px] h-[50%] bg-[#E5E5E5]" />
            <section className="flex items-center gap-2">
              <AlignButton />
              <LineHeightButton />
              {FONT_BOX_2.map((font) => (
                <button
                  onClick={() => toast.error("지원하지 않는 기능입니다.")}
                  className="flex flex-col items-center justify-center w-[22px] h-full gap-1 hover:brightness-95 bg-white"
                >
                  <img
                    src={font.icon}
                    alt={font.name}
                    className="w-auto h-[22px]"
                  />
                </button>
              ))}
            </section>
            <div className="w-[1px] h-[50%] bg-[#E5E5E5]" />
            <section className="flex items-center gap-2">
              {FONT_BOX_3.map((font) => (
                <button
                  onClick={() => toast.error("지원하지 않는 기능입니다.")}
                  className="flex flex-col items-center justify-center w-[22px] h-full gap-1 hover:brightness-95 bg-white"
                >
                  <img
                    src={font.icon}
                    alt={font.name}
                    className="w-auto h-[22px]"
                  />
                </button>
              ))}
            </section>
            <div className="w-[1px] h-[50%] bg-[#E5E5E5]" />
            <section className="flex items-center h-full gap-4">
              <button
                onClick={() => toast.error("지원하지 않는 기능입니다.")}
                className="flex items-center justify-center h-full gap-1 hover:brightness-95 bg-white"
              >
                <img
                  src="/fontbox/4-1.png"
                  alt="번역"
                  className="w-auto h-[22px]"
                />
                <span className="text-[10px]">번역</span>
              </button>
              <div className="w-[1px] h-[50%] bg-[#E5E5E5]" />
              <button
                onClick={() => toast.error("지원하지 않는 기능입니다.")}
                className="flex items-center justify-center h-full gap-1 hover:brightness-95 bg-white"
              >
                <img
                  src="/fontbox/4-2.png"
                  alt="맞춤법"
                  className="w-auto h-[22px]"
                />
                <span className="text-[10px]">맞춤법</span>
              </button>
            </section>
          </header>
        </div>
        <article className="w-full h-fit flex flex-col items-center">
          <section className="w-[1024px] min-h-screen border-r-[1px] border-l-[1px] bg-white px-20 flex flex-col gap-10 py-10">
            <div className="h-[120px]" />
            <figure className="flex items-center gap-4 ml-auto">
              <img
                src="/toolbox/image.png"
                alt="사진"
                className="w-[18px] h-[18px]"
              />
              <img
                src="/toolbox/mybox.png"
                alt="사진"
                className="w-[18px] h-[20px]"
              />
            </figure>
            <hgroup className="flex flex-col gap-10">
              <input
                onChange={({ target: { value } }) => setTitle(value)}
                value={title}
                className="outline-none text-3xl"
                placeholder="제목"
              />
              <div className="flex w-full h-[1px] bg-[#E5E5E5]" />
            </hgroup>
            {showToolbar && (
              <InlineTools
                formatText={formatText}
                style={{
                  position: "absolute",
                  top: toolbarPosition.top,
                  left: toolbarPosition.left,
                }}
              />
            )}
            <Editable
              className="outline-none w-full h-full"
              renderLeaf={renderLeaf}
              renderElement={renderElement}
              onKeyDown={handleKeyDown}
              placeholder="내용을 입력하세요!"
            />
          </section>
        </article>
      </main>
      <PasteUpload onUpload={onUpload} />
    </Slate>
  );
};

const TOOL_BOX_FRONT_BEFORE = [
  { name: "사진", icon: "/toolbox/image.png" },
  { name: "MYBOX", icon: "/toolbox/mybox.png", mock: true },
  { name: "동영상", icon: "/toolbox/video.png", mock: true },
  { name: "스티커", icon: "/toolbox/sticker.png", mock: true },
];

// { name: "인용구" },
// { name: "구분선" },

const TOOL_BOX_FRONT_AFTER = [
  { name: "장소", icon: "/toolbox/location.png", mock: true },
  {
    name: "링크",
    icon: "/toolbox/link.png",
  },
  { name: "파일", icon: "/toolbox/file.png", mock: true },
  { name: "일정", icon: "/toolbox/schedule.png", mock: true },
  { name: "소스코드", icon: "/toolbox/sourcecode.png", mock: true },
  { name: "표", icon: "/toolbox/table.png", mock: true },
  { name: "수식", icon: "/toolbox/expression.png", mock: true },
  { name: "내돈내산", icon: "/toolbox/mymoney.png", mock: true },
];

const TOOL_BOX_BACK = [
  { name: "클립", icon: "/toolbox/clip.png", mock: true },
  { name: "글감", icon: "/toolbox/poem.png", mock: true },
  { name: "라이브러리", icon: "/toolbox/library.png", mock: true },
  { name: "템플릿", icon: "/toolbox/template.png", mock: true },
];

const FONT_BOX_2 = [{ name: "list", icon: "/fontbox/2-3.png" }];

const FONT_BOX_3 = [
  { name: "headline", icon: "/fontbox/3-1.png" },
  { name: "upsubscript", icon: "/fontbox/3-2.png" },
  { name: "downsubscript", icon: "/fontbox/3-3.png" },
  { name: "special", icon: "/fontbox/3-4.png" },
  { name: "link", icon: "/fontbox/3-5.png" },
];

export default Editor;

/**
 * 
 * 
 * selection
 
const [currLine, setCurrLine] = useState(0);
  const [content, setContent] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInputChange = (e: FormEvent<HTMLDivElement>) => {
    console.log((e.target as HTMLDivElement).innerHTML);
  };

  const [selectionBoundary, setSelectionBoundary] = useState({
    start: 0,
    end: 0,
  });

  const [isSelecting, setIsSelecting] = useState(false);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    console.log(e.code);
    if (e.code === "ShiftLeft") {
      setIsSelecting(true);
    }
    if (e.code === "ArrowLeft" && isSelecting) {
      if (!selectionBoundary.start && !selectionBoundary.end) {
      }
    }
    if (e.code === "ArrowRight" && isSelecting) {
      if (selectionBoundary.end > content.length) {
        setSelectionBoundary((prev) => ({ ...prev, start: prev.start - 1 }));
      }
    }
  };

  const divRef = useRef<HTMLDivElement>(null);

  const handleClickStyling = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const text = window.getSelection()?.toString();
    var span = document.createElement("span");
    span.innerHTML = text!;
    span.style.fontWeight = "bold";
    document.execCommand("insertHTML", false, span.outerHTML);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    console.log(e.code);

    if (e.code === "ShiftLeft") {
      setIsSelecting(false);
    }
  };

  const handleClickStyling2 = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const text = window.getSelection()?.toString();
    console.log(window.getSelection());
    var span = document.createElement("div");
    span.innerHTML = text!;
    span.style.backgroundColor = "green";
    document.execCommand("insertHTML", false, span.outerHTML);
  };

 */
