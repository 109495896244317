const Leaf = (props: any) => {
  return (
    <span
      {...props.attributes}
      style={{
        fontWeight: props.leaf.bold ? "bold" : "normal",
        fontStyle: props.leaf.italic ? "italic" : "normal",
        textDecoration: props.leaf.underline ? "underline" : "unset",
        color: props.leaf.color || "black",
        backgroundColor: props.leaf.backgroundColor || "transparent",
        textAlign: props.leaf.textAlign || "left",
        lineHeight: props.leaf.lineHeight || "normal",
        fontSize: props.leaf.font || "15px",
      }}
      className={props.leaf.fontFamily}
    >
      {props.leaf.cancel ? (
        <span className="line-through">{props.children}</span>
      ) : (
        props.children
      )}
    </span>
  );
};

export default Leaf;
