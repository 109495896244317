import React from "react";

const MenuIcon = () => {
  return (
    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h25v25H0z" />
        <path
          d="M20.6 19.2a.9.9 0 1 1 0 1.8H4.4a.9.9 0 1 1 0-1.8h16.2zm0-7.6a.9.9 0 1 1 0 1.8H4.4a.9.9 0 1 1 0-1.8h16.2zm0-7.6a.9.9 0 1 1 0 1.8H4.4a.9.9 0 0 1 0-1.8h16.2z"
          fill="#000"
        />
      </g>
    </svg>
  );
};

export default MenuIcon;
