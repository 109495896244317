import React, { useState } from "react";
import Header from "../components/Header";
import Button from "../components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { mincompany } from "../apis/instance";

const Login = () => {
  const [secretKey, setSecretKey] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!secretKey) return toast.error("시크릿 키를 입력해주세요!");
    try {
      const { data } = await mincompany.post("/auth/login", secretKey, {
        headers: {
          "Content-Type": "text/plain",
        },
      });
      localStorage.setItem("token", data);
      toast.success("로그인에 성공했습니다!");
      navigate("/admin/home");
    } catch {
      toast.error("시크릿 키가 일치하지 않습니다.");
      setSecretKey("");
    }
  };

  return (
    <main className="w-screen h-screen flex items-center justify-center">
      <Header />
      <section className="flex flex-col gap-6 justify-center items-center">
        <h1 className="text-[28px] font-bold font-['Pretendard']">
          시크릿 키 로그인
        </h1>
        <input
          onChange={({ target: { value } }) => setSecretKey(value)}
          value={secretKey}
          className=" w-80 h-12 border-solid border-2 border-[#ccc] text-center font-semibold rounded-md outline-none focus:border-primary focus:transition-colors focus:duration-300"
          placeholder="시크릿 키를 입력해주세요"
        />
        <Button onClick={handleLogin}>로그인</Button>
      </section>
    </main>
  );
};

export default Login;
