import { useSlate } from "slate-react";
import { BaseEditor, Editor, Transforms } from "slate";
import { useState } from "react";
import ArrowIcon from "../../assets/ArrowIcon";

const fontArray = [11, 13, 15, 16, 19, 24, 28, 30, 34, 38];

export const FontSizeButton = () => {
  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  const [font, setFont] = useState(15);

  return (
    <>
      {isOpen && (
        <>
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="fixed w-full h-full z-[100000] top-0 left-0"
          />
          <ul className="absolute top-0 left-0 bg-white z-[200000] border-[1px] border-solid shadow-lg">
            {fontArray.map((font) => {
              return (
                <div
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  onClick={() => {
                    setIsOpen(false);
                    toggleLineHeight(editor, font);
                    setFont(font);
                  }}
                  className="py-2 px-4 h-full hover:brightness-95 bg-white cursor-pointer z-[300000]"
                  key={font}
                >
                  {font}
                </div>
              );
            })}
          </ul>
        </>
      )}
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        className="relative text-[12px] text-[#333] flex items-center gap-3"
      >
        {font}
        <ArrowIcon width={9} />
      </button>
    </>
  );
};

const toggleLineHeight = (editor: BaseEditor, font: number) => {
  (Transforms.setNodes as any)(
    editor,
    { font: `${font}px` },
    { match: (n: any) => Editor.isBlock(editor, n) }
  );
};
