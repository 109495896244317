import React from "react";
import Header from "../../components/Header";
import Button from "../../components/atoms/Button";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useModal } from "../../hooks/useModal";
import MutateProfileModal from "../../components/modals/MutateProfileModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { mincompany } from "../../apis/instance";

const AdminManageProfile = () => {
  const { data, isSuccess } = useQuery({
    queryKey: ["users"],
    queryFn: async () => (await mincompany.get("/users")).data,
  });

  const { openModal } = useModal();

  const queryClient = useQueryClient();
  const { mutateAsync: deleteProfile } = useMutation({
    mutationFn: async (id: number) => await mincompany.delete(`/users/${id}`),
  });
  const handleDelete = async (id: number) => {
    const { isConfirmed } = await Swal.fire({
      title: "이 프로필을 삭제하시겠습니까?",
      text: "해당 프로필로 쓴 댓글도 같이 삭제됩니다.",
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "red",
      cancelButtonText: "확인",
      confirmButtonColor: "gray",
      confirmButtonText: "취소",
    });
    if (!isConfirmed) {
      await deleteProfile(id);
      toast.success("프로필이 삭제되었습니다.");
      queryClient.invalidateQueries({ queryKey: ["users"] });
    }
  };

  return (
    <main className="min-h-screen w-screen">
      <Header isMockLoggedIn fill />
      <div className="w-full h-full flex flex-col p-20">
        <hgroup className="py-10 flex flex-col gap-4">
          <figure className="flex items-center w-full justify-between">
            <h1 className="text-3xl font-semibold">프로필 목록</h1>
            <Button onClick={() => openModal(<MutateProfileModal />)}>
              등록
            </Button>
          </figure>
          <div className="w-full h-[1px] bg-[#ccc]" />
        </hgroup>
        <ul className="grid grid-cols-4 gap-8 justify-between">
          {isSuccess &&
            data.map((profile: any) => (
              <li
                key={profile.id}
                className="w-full p-6 border-solid border-2 border-[#f2f3f7] flex items-center justify-between"
              >
                <figure className="flex items-center gap-4">
                  <img
                    src={profile.profileImageUrl}
                    alt="mock"
                    className="w-10 h-10 rounded-full"
                  />
                  <span className="text-lg font-semibold">
                    {profile.nickname}
                  </span>
                </figure>
                <figure className="flex items-center gap-1">
                  <Button
                    onClick={() =>
                      openModal(
                        <MutateProfileModal
                          edit
                          profileUrlOg={profile.profileImageUrl}
                          nicknameOg={profile.nickname}
                          id={profile.id}
                        />
                      )
                    }
                    className="!px-3 !py-[1px] !text-[11px]"
                  >
                    수정
                  </Button>
                  <Button
                    onClick={() => handleDelete(profile.id)}
                    className="!px-3 !py-[1px] !text-[11px] bg-red-500"
                  >
                    삭제
                  </Button>
                </figure>
              </li>
            ))}
        </ul>
      </div>
    </main>
  );
};

export default AdminManageProfile;
