import React, { useState } from "react";
import { mincompany } from "../../apis/instance";
import { authorization } from "../../apis/authorization";
import Button from "../atoms/Button";
import { Transforms } from "slate";
import { useSlate } from "slate-react";
import { useSetAtom } from "jotai";
import { mockAtom } from "../../context";

const EmbedModal = () => {
  const setMock = useSetAtom(mockAtom);
  const [data, setData] = useState<any>();
  const [flag, setFlag] = useState(0);

  const [url, setUrl] = useState("");
  const getEmbed = async () => {
    try {
      const { data } = await mincompany.get("/embeds", {
        params: { url },
        ...authorization(),
      });
      console.log(data);
      setData(data);
      setFlag(1);
    } catch {
      setFlag(-1);
    }
  };

  const editor = useSlate();
  const handleSend = () => {
    const newBlock = {
      type: "link",
      children: [{ text: "", data }],
    };

    Transforms.insertNodes(editor, newBlock);

    const newBlock2 = {
      type: "paragraph",
      children: [{ text: " ", font: "15px" }],
    };

    Transforms.insertNodes(editor, newBlock2);
    setMock(false);
  };

  return (
    <>
      <div className="w-screen h-screen fixed left-0 top-0 z-[10000] bg-black opacity-35" />
      <div className="z-[20000] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[60vw] h-[60vh] bg-white flex flex-col justify-center items-center px-20 gap-10">
        <div className="w-full h-12 relative border-solid border-primary border-[1.5px] flex items-center">
          <input
            onChange={({ target: { value } }) => setUrl(value)}
            value={url}
            className="w-full h-full absolute outline-none px-4"
            placeholder="링크를 입력하세요"
          />
          <button
            onClick={getEmbed}
            className="absolute right-0 px-5 bg-primary h-full text-white font-semibold"
          >
            검색
          </button>
        </div>
        {flag === -1 ? (
          <span className="text-red-600">유효한 사이트 주소가 아닙니다.</span>
        ) : (
          flag === 1 && (
            <a
              href={data.url}
              className="w-full h-32 flex items-center border-solid border-[1px] border-[#ccc] cursor-pointer"
            >
              <img src={data.image} alt="logo" className="h-full" />
              <div className="flex flex-col p-6 h-full justify-between">
                <h1 className=" font-semibold">{data.title}</h1>
                <span className=" text-ellipsis overflow-hidden line-clamp-1">
                  {data.description}
                </span>
                <span>{data.url}</span>
              </div>
            </a>
          )
        )}
        <Button onClick={handleSend}>확인</Button>
      </div>
    </>
  );
};

export default EmbedModal;
