import React from "react";

const AddPeopleIcon = () => {
  return (
    <svg width="8" height="9" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 .5a.65.65 0 0 1 .65.65l-.001 2.699 2.701.001a.65.65 0 1 1 0 1.3l-2.701-.001.001 2.701a.65.65 0 1 1-1.3 0l-.001-2.7H.65a.65.65 0 1 1 0-1.3h2.699l.001-2.7A.65.65 0 0 1 4 .5z"
        fill="#03C75A"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default AddPeopleIcon;
