import React, { ChangeEvent, useRef, useState } from "react";
import Button from "../atoms/Button";
import { useModal } from "../../hooks/useModal";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mincompany } from "../../apis/instance";
import { authorization } from "../../apis/authorization";

interface MutateProfileModalProps {
  profileUrlOg?: string;
  nicknameOg?: string;
  edit?: boolean;
  id?: number;
}

const MutateProfileModal = ({
  profileUrlOg = "",
  nicknameOg = "",
  edit,
  id,
}: MutateProfileModalProps) => {
  const { closeModal } = useModal();
  const [profileUrl, setProfileUrl] = useState(
    profileUrlOg || "/img_profile_default.png"
  );
  const queryClient = useQueryClient();
  const [nickname, setNickname] = useState(nicknameOg);

  const handleProfileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const { data } = await mincompany.post(
        "/files",
        formData,
        authorization()
      );
      setProfileUrl(data);
    }
  };

  const { mutateAsync: editMutate } = useMutation({
    mutationFn: (id: number) =>
      mincompany.patch(
        `/users/${id}`,
        {
          profileImageUrl: profileUrl,
          nickname,
        },
        authorization()
      ),
  });

  const { mutateAsync: createMutate } = useMutation({
    mutationFn: () =>
      mincompany.post(
        "/users",
        {
          profileImageUrl: profileUrl,
          nickname,
        },
        authorization()
      ),
  });

  const handleEdit = async (id: number) => {
    if (!nickname) return toast.error("닉네임을 입력해주세요.");
    // s3
    await editMutate(id);
    toast.success(`프로필을 변경했습니다!`);
    queryClient.invalidateQueries({ queryKey: ["users"] });
    closeModal();
  };

  const handleRegister = async () => {
    if (!nickname) return toast.error("닉네임을 입력해주세요.");
    // s3
    // const { data } = await fun();
    toast.success(`프로필을 등록했습니다!`);
    await createMutate();
    queryClient.invalidateQueries({ queryKey: ["users"] });
    closeModal();
  };

  return (
    <main className="w-full p-6 border-solid border-2 border-[#f2f3f7] flex items-center flex-col justify-between bg-white gap-6">
      <figure className="flex items-center gap-4">
        <div className="rounded-full w-10 h-10  relative">
          <img
            src={profileUrl}
            alt="mock"
            className="w-full h-full rounded-full absolute"
          />
          <label
            htmlFor="file"
            className="w-full h-full cursor-pointer rounded-full bg-black bg-opacity-20 absolute flex items-center justify-center"
          >
            <span className="text-3xl text-white font-bold">+</span>
          </label>
          <input
            onChange={handleProfileUpload}
            id="file"
            type="file"
            className="hidden"
          />
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-semibold text-sm">닉네임</span>
          <input
            className="text-sm w-64 font-semibold border-solid border-[1.5px] outline-none border-[#ccc] rounded-md focus:border-primary focus:transition-colors focus:duration-300 px-2 py-2"
            onChange={({ target: { value } }) => setNickname(value)}
            value={nickname}
          />
        </div>
      </figure>
      <figure className="flex items-center gap-1 ml-auto">
        <Button
          onClick={closeModal}
          className="!px-3 !py-[1px] !text-[11px] bg-red-500"
        >
          취소
        </Button>
        <Button
          onClick={edit && id ? () => handleEdit(id) : handleRegister}
          className="!px-3 !py-[1px] !text-[11px]"
        >
          {edit ? "변경" : "등록"}
        </Button>{" "}
      </figure>
    </main>
  );
};

export default MutateProfileModal;
