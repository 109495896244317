import React from "react";

const SearchIcon = () => {
  return (
    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h25v25H0z" />
        <path
          d="M11.37 2a9.37 9.37 0 0 1 9.37 9.37c0 2.263-.802 4.34-2.138 5.959l3.761 3.761a.9.9 0 0 1-1.273 1.273l-3.761-3.761a9.332 9.332 0 0 1-5.959 2.138 9.37 9.37 0 0 1 0-18.74zm0 1.8a7.57 7.57 0 1 0 5.222 13.05.904.904 0 0 1 .256-.26A7.57 7.57 0 0 0 11.37 3.8z"
          fill="#000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
