import React, { SVGProps } from "react";

const ToolBoxArrowIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 0L0 6H8L4 0Z" fill="#555" />
    </svg>
  );
};

export default ToolBoxArrowIcon;
