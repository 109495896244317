import { useSlate } from "slate-react";
import { BaseEditor, Editor, Text, Transforms } from "slate";

export const CancelButton = () => {
  const editor = useSlate();
  return (
    <button
      onMouseDown={(e) => {
        e.preventDefault();
        toggleCancelMark(editor);
      }}
      className="flex flex-col items-center justify-center w-[22px] h-full gap-1 hover:brightness-95 bg-white"
    >
      <img src="/fontbox/1-4.png" alt="cancel" className="w-auto h-[22px]" />
    </button>
  );
};

const toggleCancelMark = (editor: BaseEditor) => {
  const isActive = isCancelMarkActive(editor);
  (Transforms.setNodes as any)(
    editor,
    { cancel: isActive ? null : true },
    { match: (n: any) => Text.isText(n), split: true }
  );
};

const isCancelMarkActive = (editor: BaseEditor) => {
  const [match]: any = Editor.nodes(editor, {
    match: (n) => (n as any).cancel === true,
    universal: true,
  });
  return !!match;
};
