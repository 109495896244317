import React, { ChangeEvent, useState } from "react";
import Button from "../atoms/Button";
import { toast } from "react-toastify";
import SelectProfile from "../atoms/SelectProfile";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mincompany } from "../../apis/instance";
import { authorization } from "../../apis/authorization";
import Check from "../atoms/Check";

interface ReCommentRegisterProps {
  onClose: () => void;
  parentId: any;
  postId: any;
}

const ReCommentRegister = ({
  postId,
  parentId,
  onClose,
}: ReCommentRegisterProps) => {
  const [userId, setUserId] = useState(0);
  const [contents, setContents] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [like, setLike] = useState<number>();
  const [isPrivate, setIsPrivate] = useState(false);

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  };

  const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };

  const handleLikeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!isNaN(Number(value))) {
      setLike(Number(value));
    }
  };

  const { mutateAsync: commentRegist } = useMutation({
    mutationFn: (res: any) =>
      mincompany.post("/comments", res, authorization()),
  });

  const queryClient = useQueryClient();

  const handleRegister = async () => {
    await commentRegist({
      userId,
      postId,
      parentCommentId: parentId,
      content: contents,
      isPrivate,
      likeCount: like,
      createdAt: new Date(`${date}T${time}Z`),
    });
    toast.success("답글을 생성했습니다!");
    queryClient.invalidateQueries({ queryKey: ["comment", postId] });
    onClose();
  };

  return (
    <section className="w-full flex px-6 bg-[#f8f8f8] p-[16px] border-solid border-[2px] border-primary rounded-md gap-4">
      <div className="w-[11px] h-[11px] border-solid border-b-[1px] border-l-[1px] border-black opacity-[0.4]" />
      <section className=" w-full flex flex-col gap-2">
        <header className="flex items-center justify-between">
          <SelectProfile setUserId={setUserId} />
          <figure className="flex items-center gap-1">
            <Button
              onClick={onClose}
              className="!text-[10px] !px-3 !py-[0] !bg-red-500"
            >
              취소
            </Button>
            <Button
              onClick={handleRegister}
              className="!text-[10px] !px-3 !py-[0]"
            >
              등록
            </Button>
          </figure>
        </header>
        <article className="flex flex-col">
          <textarea
            className="text-[13px] font-['helvetica'] p-4 border-solid border-[1.5px] border-[#ccc] outline-none"
            onChange={({ target: { value } }) => setContents(value)}
            value={contents}
          />
          <div className="flex items-center py-2">
            <input
              onChange={handleDateChange}
              value={date}
              type="date"
              className="bg-transparent text-sm"
            />
            <input
              onChange={handleTimeChange}
              value={time}
              type="time"
              className="bg-transparent text-sm"
            />
          </div>
          <div className="flex items-center gap-1">
            <span className="text-sm font-semibold">좋아요 수</span>
            <input
              placeholder="0"
              onChange={handleLikeChange}
              value={like}
              className="bg-transparent rounded-md w-32 text-sm border-solid border-2 border-[#ccc] outline-none focus:border-primary focus:duration-300 transition-colors px-2"
            />
            <div className="ml-6 flex gap-2 items-center">
              <span className="text-sm font-semibold">비공개 여부</span>
              <Check val={isPrivate} set={() => setIsPrivate((pr) => !pr)} />
            </div>
          </div>
        </article>
      </section>
    </section>
  );
};

export default ReCommentRegister;
