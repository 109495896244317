import React from "react";
import { useModal } from "../../hooks/useModal";
import { useAtomValue } from "jotai";
import { modalAtom } from "../../context";
import classNames from "classnames";

const GlobalModal = () => {
  const { closeModal } = useModal();
  const { component, visible } = useAtomValue(modalAtom);

  return (
    <>
      <div
        className={classNames(
          "fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[2000]",
          !visible && "hidden"
        )}
      >
        {component}
      </div>
      <div
        onClick={closeModal}
        className={classNames(
          "fixed top-0 left-0 w-screen h-screen bg-black opacity-[0.4] z-[1000]",
          !visible && "hidden"
        )}
      />
    </>
  );
};

export default GlobalModal;
