import React from "react";

const MainEtcIcon = () => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <circle fill="#979797" cx="12" cy="12" r="1.5" />
        <circle fill="#979797" cx="12" cy="18.5" r="1.5" />
        <circle fill="#979797" cx="12" cy="5.5" r="1.5" />
      </g>
    </svg>
  );
};

export default MainEtcIcon;
