import { useSetAtom } from "jotai";
import { ReactNode, useCallback } from "react";
import { modalAtom } from "../context";

export const useModal = () => {
  const setModal = useSetAtom(modalAtom);

  const openModal = useCallback(
    (component: ReactNode) => {
      setModal({ component, visible: true });
    },
    [setModal]
  );

  const closeModal = useCallback(() => {
    setModal({ component: null, visible: false });
  }, [setModal]);

  return { openModal, closeModal };
};
