import { atom } from "jotai";
import { ReactNode } from "react";

export const modalAtom = atom<{ component: ReactNode; visible: boolean }>({
  component: null,
  visible: false,
});

export const urlAtom = atom("");

export const mockAtom = atom(false);
