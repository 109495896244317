import React, { ChangeEvent, useEffect, useState } from "react";
import Button from "../atoms/Button";
import ReCommentItem from "./ReCommentItem";
import { toast } from "react-toastify";
import ReCommentRegister from "./ReCommentRegister";
import LikeIcon from "../../assets/LikeIcon";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mincompany } from "../../apis/instance";
import { authorization } from "../../apis/authorization";
import Check from "../atoms/Check";

const CommentItem = ({ postId, readOnly, comment }: any) => {
  const [isReplyMode, setIsReplyMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [contents, setContents] = useState(comment.content);
  const [like, setLike] = useState<number>();
  const [date, setDate] = useState("2022-08-16");
  const [time, setTime] = useState("15:24");
  const [isPrivate, setIsPrivate] = useState(false);

  useEffect(() => {
    setIsPrivate(comment.isPrivate);
    setLike(comment.likeCount);
    setContents(comment.content);
    setDate(comment.createdAt.split("T")[0]);
    const a = comment.createdAt.split("T")[1].split(":");
    setTime(`${a[0]}:${a[1]}`);
  }, [comment]);

  const handleLikeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!isNaN(Number(value))) {
      setLike(Number(value));
    }
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  };

  const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };

  const queryClient = useQueryClient();

  const { mutateAsync: editMutate } = useMutation({
    mutationFn: async (res: any) =>
      await mincompany.patch(`/comments/${res.id}`, res.data, authorization()),
  });
  const handleEdit = async () => {
    const utcDate = new Date(`${date}T${time}Z`);
    await editMutate({
      id: comment.id,
      data: {
        content: contents,
        isPrivate,
        likeCount: like,
        createdAt: utcDate,
      },
    });
    toast.success("댓글을 수정헀습니다!");
    queryClient.invalidateQueries({ queryKey: ["comment"] });

    setIsEditMode(false);
  };

  const { mutateAsync: deleteMutate } = useMutation({
    mutationFn: async (id: number) =>
      await mincompany.delete(`/comments/${id}`, authorization()),
  });
  const handleDelete = async () => {
    await deleteMutate(comment.id);
    toast.success("댓글을 삭제했습니다!");
    queryClient.invalidateQueries({ queryKey: ["comment"] });
  };

  const handleReply = () => {
    setIsReplyMode(true);
  };

  return (
    <section className="w-full px-2">
      <section className="w-full flex flex-col gap-3 border-solid border-t-[1px] bg-white border-[#DADADA] pt-[26px]">
        <header className="flex items-center justify-between px-4">
          {!isEditMode && isPrivate ? (
            <span className="text-[14px] py-2">비밀 댓글입니다.</span>
          ) : (
            <hgroup className="flex items-center gap-2">
              <img
                src={comment.user.profileImageUrl}
                alt="profile"
                className="w-6 h-6 rounded-full"
              />
              <span className="text-[13px] font-[700] font-['helvetica'] text-[#2a2a2a]">
                {comment.user.nickname}
              </span>
            </hgroup>
          )}
          {!readOnly && (
            <figure className="flex items-center gap-1">
              {isEditMode ? (
                <>
                  <Button
                    onClick={() => setIsEditMode(false)}
                    className="!text-[10px] !px-3 !py-[0] !bg-red-500"
                  >
                    취소
                  </Button>
                  <Button
                    onClick={handleEdit}
                    className="!text-[10px] !px-3 !py-[0]"
                  >
                    수정
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={handleReply}
                    className="!text-[10px] !px-3 !py-[0] "
                  >
                    답글
                  </Button>
                  <Button
                    onClick={() => setIsEditMode(true)}
                    className="!text-[10px] !px-3 !py-[0]"
                  >
                    수정
                  </Button>
                  <Button
                    onClick={handleDelete}
                    className="!text-[10px] !px-3 !py-[0] !bg-red-500"
                  >
                    삭제
                  </Button>
                </>
              )}
            </figure>
          )}
        </header>
        <article className="flex flex-col px-4 gap-1">
          {isEditMode ? (
            <textarea
              className="text-[13px] font-['helvetica'] p-4 border-solid border-[1.5px] border-[#ccc] outline-none"
              onChange={({ target: { value } }) => setContents(value)}
              value={contents}
            />
          ) : (
            !isPrivate && (
              <p className="text-[13px] whitespace-pre-wrap font-['helvetica']">
                {contents}
              </p>
            )
          )}
          {isEditMode ? (
            <div className="flex items-center py-2">
              <input
                onChange={handleDateChange}
                value={date}
                type="date"
                className="bg-transparent text-sm"
              />
              <input
                onChange={handleTimeChange}
                value={time}
                type="time"
                className="bg-transparent text-sm"
              />
            </div>
          ) : (
            <time className="text-[12px] text-[#666]">
              {dayjs(comment.createdAt).format("YYYY. M. D. HH:m")}
            </time>
          )}
          {isEditMode ? (
            <div className="flex items-center gap-1">
              <span className="text-sm font-semibold">좋아요 수</span>
              <input
                placeholder="0"
                onChange={handleLikeChange}
                value={like}
                className="bg-transparent rounded-md w-32 text-sm border-solid border-2 border-[#ccc] outline-none focus:border-primary focus:duration-300 transition-colors px-2"
              />
              <div className="ml-6 flex gap-2 items-center">
                <span className="text-sm font-semibold">비공개 여부</span>
                <Check val={isPrivate} set={() => setIsPrivate((pr) => !pr)} />
              </div>
            </div>
          ) : (
            !isPrivate && (
              <div className="flex w-fit items-center gap-1 p-1 px-2 border-[1px] border-[#CFCFCF] justify-center ml-auto">
                <LikeIcon />
                <p className="text-[12px]">{like || 0}</p>
              </div>
            )
          )}
        </article>
        <div className="flex flex-col">
          {isReplyMode && (
            <ReCommentRegister
              postId={postId}
              parentId={comment.id}
              onClose={() => setIsReplyMode(false)}
            />
          )}
          {comment.subComments.map((recomment: any) => (
            <ReCommentItem
              readOnly={readOnly}
              recomment={recomment}
              key={recomment.id}
            />
          ))}
        </div>
      </section>
    </section>
  );
};

export default CommentItem;
