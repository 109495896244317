import { useSlate } from "slate-react";
import { BaseEditor, Editor, Transforms } from "slate";
import { useState } from "react";

export const LineHeightButton = () => {
  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen && (
        <>
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="fixed w-full h-full z-[100000] top-0 left-0"
          />
          <ul className="absolute top-36 bg-white z-[200000] border-[1px] border-solid shadow-lg">
            {Array.from(Array(7)).map((_, i) => {
              const percentage = (i + 15) * 10;
              return (
                <div
                  onClick={() => {
                    setIsOpen(false);
                    toggleLineHeight(editor, percentage);
                  }}
                  className="p-2 h-full hover:brightness-95 bg-white cursor-pointer"
                  key={percentage}
                >
                  {percentage}%
                </div>
              );
            })}
          </ul>
        </>
      )}
      <button
        onClick={() => setIsOpen(true)}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        className="relative flex flex-col items-center justify-center w-[22px] h-full gap-1 hover:brightness-95 bg-white"
      >
        <img src="/fontbox/2-2.png" alt="align" className="w-auto h-[22px]" />
      </button>
    </>
  );
};

const toggleLineHeight = (editor: BaseEditor, lineHeight: number) => {
  (Transforms.setNodes as any)(
    editor,
    { lineHeight: `${lineHeight}%` },
    { match: (n: any) => Editor.isBlock(editor, n) }
  );
};
