import { useSlate } from "slate-react";
import { BaseEditor, Text, Transforms } from "slate";
import { SketchPicker } from "react-color";
import { useState } from "react";

export const BackgroundButton = () => {
  const [isActive, setIsActive] = useState(false);
  const [color, setColor] = useState("#000");
  const editor = useSlate();

  const handleChangeComplete = (color: any) => {
    setColor(color.hex);
    toggleBackgroundColorMark(editor, color.hex);
  };

  return (
    <>
      {isActive && (
        <>
          <div
            onClick={() => {
              setIsActive(false);
            }}
            className="fixed w-full h-full z-[100000] top-0 left-0"
          />
          <SketchPicker
            color={color}
            onChangeComplete={handleChangeComplete}
            className="!z-[200000] fixed top-32"
          />
        </>
      )}
      <button
        onBlur={() => setIsActive(false)}
        onClick={() => setIsActive(true)}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        className="flex flex-col items-center justify-center w-[22px] h-full gap-1 hover:brightness-95 bg-white"
      >
        <img
          src="/fontbox/1-6.png"
          alt="background-color"
          className="w-auto h-[22px]"
        />
      </button>
    </>
  );
};

const toggleBackgroundColorMark = (
  editor: BaseEditor,
  backgroundColor: string
) => {
  (Transforms.setNodes as any)(
    editor,
    { backgroundColor },
    {
      match: (n: any) => Text.isText(n),
      split: true,
    }
  );
};
