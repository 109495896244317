import React from "react";

const LikeIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 12.5L7.146 12.854C7.19245 12.9006 7.24762 12.9375 7.30837 12.9627C7.36911 12.9879 7.43423 13.0009 7.5 13.0009C7.56577 13.0009 7.63089 12.9879 7.69163 12.9627C7.75238 12.9375 7.80755 12.9006 7.854 12.854L7.5 12.5ZM7.5 2.5L7.146 2.854C7.19245 2.90056 7.24762 2.93751 7.30837 2.96271C7.36911 2.98792 7.43423 3.00089 7.5 3.00089C7.56577 3.00089 7.63089 2.98792 7.69163 2.96271C7.75238 2.93751 7.80755 2.90056 7.854 2.854L7.5 2.5ZM7.854 12.146L1.889 6.182L1.182 6.889L7.146 12.854L7.854 12.146ZM13.111 6.182L7.146 12.146L7.854 12.854L13.818 6.889L13.111 6.182ZM6.182 1.889L7.146 2.854L7.854 2.146L6.889 1.182L6.182 1.889ZM7.854 2.854L8.818 1.889L8.111 1.182L7.146 2.146L7.854 2.854ZM10.964 0C9.894 0 8.868 0.425 8.111 1.182L8.818 1.889C9.38745 1.32028 10.1592 1.00058 10.964 1V0ZM14 4.036C14 4.841 13.68 5.613 13.11 6.182L13.818 6.889C14.5743 6.13205 14.9994 5.106 15 4.036H14ZM15 4.036C15 2.96559 14.5748 1.93901 13.8179 1.18212C13.061 0.42522 12.0344 0 10.964 0V1C11.7692 1 12.5414 1.31986 13.1108 1.88922C13.6801 2.45858 14 3.2308 14 4.036H15ZM4.036 1C4.841 1 5.613 1.32 6.182 1.89L6.889 1.182C6.13205 0.425734 5.106 0.000641721 4.036 0V1ZM1 4.036C1 3.2308 1.31986 2.45858 1.88922 1.88922C2.45858 1.31986 3.2308 1 4.036 1V0C2.96559 0 1.93901 0.42522 1.18212 1.18212C0.42522 1.93901 0 2.96559 0 4.036H1ZM1.889 6.182C1.31992 5.61271 1.00085 4.84095 1 4.036H0C0 5.106 0.425 6.132 1.182 6.889L1.889 6.182Z"
        fill="black"
      />
    </svg>
  );
};

export default LikeIcon;
