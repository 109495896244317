import React from "react";

const TabListIcon = () => {
  return (
    <svg width="26" height="25" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(.5)" fill="none" fillRule="evenodd">
        <path d="M0 0h25v25H0z" />
        <path
          d="M19 3a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h13zm0 1.8H6a1.2 1.2 0 0 0-1.194 1.077L4.8 6v13a1.2 1.2 0 0 0 1.077 1.194L6 20.2h13a1.2 1.2 0 0 0 1.194-1.077L20.2 19V6a1.2 1.2 0 0 0-1.077-1.194L19 4.8z"
          fill="#000"
          fillRule="nonzero"
        />
        <rect fill="#000" x="10.75" y="15.15" width="7" height="1.5" rx=".75" />
        <rect fill="#000" x="10.75" y="11.75" width="7" height="1.5" rx=".75" />
        <rect fill="#000" x="10.75" y="8.35" width="7" height="1.5" rx=".75" />
        <rect fill="#000" x="7.25" y="8.35" width="2" height="1.5" rx=".75" />
        <rect fill="#000" x="7.25" y="11.75" width="2" height="1.5" rx=".75" />
        <rect fill="#000" x="7.25" y="15.15" width="2" height="1.5" rx=".75" />
      </g>
    </svg>
  );
};

export default TabListIcon;
