import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Post from "./pages/Post";
import Login from "./pages/Login";
import AdminHome from "./pages/(admin)/AdminHome";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import AdminPost from "./pages/(admin)/AdminPost";
import NotFound from "./pages/NotFound";
import AdminManageProfile from "./pages/(admin)/AdminManageProfile";
import { Provider as JotaiProvider } from "jotai";
import GlobalModal from "./components/modals/GlobalModal";
import AdminEdit from "./pages/(admin)/AdminEdit";
import AdminWrite from "./pages/(admin)/AdminWrite";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <JotaiProvider>
        <BrowserRouter>
          <GlobalModal />
          <ToastContainer autoClose={2000} hideProgressBar />
          <Routes>
            <Route path="/post/:id" element={<Post />} />
            <Route path="/login" element={<Login />} />
            {/* -- admin -- */}
            <Route path="/admin/home" element={<AdminHome />} />
            <Route path="/admin/:id" element={<AdminPost />} />
            <Route path="/admin/:id/edit" element={<AdminEdit />} />
            <Route path="/admin/write" element={<AdminWrite />} />
            <Route
              path="/admin/manage-profile"
              element={<AdminManageProfile />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </JotaiProvider>
    </QueryClientProvider>
  );
};

export default App;
